import { useFeature } from '@amzn/sitc-frontend/hooks';
import type { NavContent, UtilityItem } from '@amzn/sitc-frontend/types';
import { getStagePath } from '@amzn/sitc-frontend/utils';
import type { MaybeUndefined } from '@amzn/sitc-frontend-types/helper';
import type { AmazonPerson } from '@amzn/studios-domain-authority-types/isdg';

import { defaultErrorUserProfileUtility } from '../configs/user-profile';

const badgePhotoUrl = 'https://badgephotos.corp.amazon.com/?uid=';

export const useDefaultUserProfileContent = (userData: MaybeUndefined<AmazonPerson>): UtilityItem => {
  const userProfileAppEnabled = useFeature('enableUserProfileApp');
  const userProfileNotificationEnabled = useFeature('enableUserProfileNotification');
  const userProfileLocalizationEnabled = useFeature('enableUserProfileLocalization');

  const { loginId = '', firstName = '', lastName = '', jobProfileName = '' } = userData || {};

  if (!loginId || !firstName || !lastName || !jobProfileName) {
    return defaultErrorUserProfileUtility;
  }

  const navContents: NavContent[] = [
    {
      key: 'user-details',
      navItems: [
        {
          avatarSource: `${badgePhotoUrl}${loginId}`,
          primary: `${firstName} ${lastName}`,
          secondary: jobProfileName,
          disabled: true,
        },
      ],
    },
  ];

  const userProfileNavContent: NavContent = {
    key: 'user-profile-app',
    navItems: [
      {
        primary: 'universal_header.utility.profile.user_profile_app.your_profile.primary',
        iconKey: 'AccountCircleOutlined',
        path: getStagePath('https://[{stage}.]amazonbacklot.com/user-profile/your-profile'),
      },
      ...(userProfileNotificationEnabled
        ? [
            {
              primary: 'universal_header.utility.profile.user_profile_app.notification.primary',
              iconKey: 'NotificationsNone',
              path: getStagePath('https://[{stage}.]amazonbacklot.com/user-profile/notification-preferences'),
            },
          ]
        : []),
      ...(userProfileLocalizationEnabled
        ? [
            {
              primary: 'universal_header.utility.profile.user_profile_app.localization.primary',
              iconKey: 'LocationOnOutlinedIcon',
              path: getStagePath('https://[{stage}.]amazonbacklot.com/user-profile/localization'),
            },
          ]
        : []),
      {
        primary: 'universal_header.utility.profile.user_profile_app.theme.primary',
        iconKey: 'Brightness6OutlinedIcon',
        path: getStagePath('https://[{stage}.]amazonbacklot.com/user-profile/theme'),
      },
    ],
  };

  if (userProfileAppEnabled) {
    navContents.push(userProfileNavContent);
  }

  return {
    primary: 'profile',
    key: 'profile',
    iconKey: 'AccountCircleOutlined',
    toolTip: 'universal_header.utility.profile.tooltip',
    navContents,
  };
};
