import type { SearchResult } from '@amzn/studios-origin-search-app-shared-types';
import type { UniversalHeaderConfig } from '@amzn/studios-universal-header-app-shared-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';

import { headerContent, headerLinkA, headerLinkB } from './headerLinks';
import { getAppSwitcherContent, getGroupedAppSwitcherContent } from './navContents';

export const getUniversalHeaderLogo = (stage: string): UniversalHeaderConfig => ({
  productIdentifier: 'Universal Header Demo - Logo Section',
  productIdentifierPath: `https://studios-universal-header.app.${stage}.frontend-platform.studios.a2z.com/`,
  logo: (
    <img src="https://m.media-amazon.com/images/G/01/backlot/assets/img/source-light.svg" alt="logo" style={{ margin: '0 2px 0 8px' }} />
  ),
});

export const MockSelect: React.FC = () => (
  <Select
    sx={{ ml: 2 }}
    size="small"
    name="mock-logo-secondary-component"
    label="Mock value"
    defaultValue={20}
    onChange={(event) => console.log(event)}>
    <MenuItem value={10}>Ten</MenuItem>
    <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem>
  </Select>
);

export const getUniversalHeaderWithAppSwitcher = (stage: string): UniversalHeaderConfig => {
  const appSwitcherContent = getAppSwitcherContent(stage);
  return {
    productIdentifier: 'Universal Header Demo - App Switcher',
    appSwitcherContent,
  };
};

export const getUniversalHeaderWithGroupedAppSwitcher = (stage: string): UniversalHeaderConfig => {
  const appSwitcherContent = getGroupedAppSwitcherContent(stage);
  return {
    productIdentifier: 'Universal Header Demo - Grouped App Switcher',
    appSwitcherContent,
  };
};

export const getUniversalHeaderWithOriginSearch = (): UniversalHeaderConfig => ({
  originSearch: {
    enable: true,
    getSearchResultNavigatePath(searchResult: SearchResult) {
      const titleDocumentId = searchResult.documentIdentifier;
      return `/titles/${titleDocumentId}/update`;
    },
  },
});

export const universalHeaderWithHeaderLinks: UniversalHeaderConfig = {
  productIdentifier: 'Universal Header Demo - Header Links',
  headerLinks: [headerLinkA, headerLinkB, headerContent],
};

export const universalHeaderLogoSecondaryComponent: UniversalHeaderConfig = {
  productIdentifier: 'Universal Header Demo',
  headerLinks: [headerLinkA, headerLinkB, headerContent],
  LogoSecondaryComponent: MockSelect,
};
