import { createMicroAppConfigContext } from '@amzn/sitc-frontend/contexts';
import type { MicroAppConfigBaseResult } from '@amzn/sitc-frontend/types';

export const appDomainTemplate = 'studios-universal-header.app[.{stage}].frontend-platform.studios.a2z.com';

interface FallbackConfigs {
  fallbackTimeMs: number;
}

export type MicroAppConfigResultType = MicroAppConfigBaseResult & {
  fallback: FallbackConfigs;
};

export const MicroAppConfigContext = createMicroAppConfigContext<MicroAppConfigResultType>();

export const getStage = (): string => {
  const { hostname } = window.location;

  return hostname === 'localhost' ? 'dev' : hostname.match(/.*\b(dev|beta|gamma)\b.*/)?.[1] || '';
};
