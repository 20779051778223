import type { Radio } from '@amzn/sitc-frontend-micro-app-sdk-core/radio';
import type { UniversalHeaderConfig } from '@amzn/studios-universal-header-app-shared-types';
import { useEffect, useState } from 'react';

type ConfigureEventPayload = {
  appName: string;
  config: UniversalHeaderConfig;
};

type ConfiguredEventPayload = {
  appName: string;
  success: boolean;
};

enum EventNames {
  Configure = 'configure',
  Configured = 'configured',
}

const channelName = 'universal-header';

interface UniversalHeaderProps {
  radio: Radio;
  microAppName: string;
  universalHeaderConfig: UniversalHeaderConfig;
}

export const useUniversalHeader = ({ radio, microAppName, universalHeaderConfig }: UniversalHeaderProps) => {
  const [isConfigured, setIsConfigured] = useState(false);
  const channel = radio.getChannel(channelName);

  useEffect(() => {
    const configurationEventSubscription = channel?.subscribe<ConfiguredEventPayload>(EventNames.Configured, (event) => {
      if (event.payload?.appName === microAppName && !isConfigured) {
        setIsConfigured(true);
      }
    });

    if (!isConfigured) {
      channel?.publish<ConfigureEventPayload>(EventNames.Configure, {
        payload: {
          appName: microAppName,
          config: universalHeaderConfig,
        },
      });
    }
    return () => {
      configurationEventSubscription?.unsubscribe();
    };
  }, [isConfigured, setIsConfigured]);
};
