import type { UniversalHeaderProps } from '@amzn/sitc-frontend/components';
import { AppLogoSources } from '@amzn/sitc-frontend/constants';
import type { AppSwitcherContent, UtilityItem } from '@amzn/sitc-frontend/types';
import type { UniversalHeaderConfig } from '@amzn/studios-universal-header-app-shared-types';

export const getUniversalHeaderProps = ({
  universalHeaderConfig,
  themeToggleEnabled,
  onThemeChange,
  defaultAppSwitcherContent,
  defaultUserProfileContent,
}: {
  universalHeaderConfig: UniversalHeaderConfig;
  themeToggleEnabled: boolean;
  onThemeChange: (theme: string) => void;
  defaultAppSwitcherContent: AppSwitcherContent[];
  defaultUserProfileContent: UtilityItem;
}): UniversalHeaderProps => {
  const { disableAppSwitcher, appSwitcherContent, disableUserProfileUtility, utilities, ...restUniversalHeaderProps } =
    universalHeaderConfig;

  let universalHeaderProps = { ...restUniversalHeaderProps };

  if (!universalHeaderProps.productIdentifier && !universalHeaderProps.logo && !universalHeaderProps.logoSrc) {
    universalHeaderProps = { ...universalHeaderProps, ...{ logoSrc: AppLogoSources.backlot } };
  }

  if (!disableAppSwitcher) {
    universalHeaderProps = { ...universalHeaderProps, ...{ appSwitcherContent: appSwitcherContent || defaultAppSwitcherContent } };
  }

  if (!disableUserProfileUtility) {
    universalHeaderProps = {
      ...universalHeaderProps,
      ...{ utilities: utilities ? [...utilities, defaultUserProfileContent] : [defaultUserProfileContent] },
    };
  }

  if (themeToggleEnabled) {
    universalHeaderProps = { ...universalHeaderProps, ...{ enableThemeSwitcher: themeToggleEnabled, onThemeChange } };
  }

  return universalHeaderProps;
};
