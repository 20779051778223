import type { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import type { Radio } from '@amzn/sitc-frontend-micro-app-sdk-core/radio';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React from 'react';

import { EventNames } from '../../configs/events';
import { useEventBus } from '../../hooks/event-bus';
import { useUniversalHeader } from '../../hooks/universal-header';
import type { ConfigureEventPayload } from '../../schemas/configure-event/ts-schema';
import { getStage } from '../../utils/app';
import {
  getUniversalHeaderLogo,
  getUniversalHeaderWithAppSwitcher,
  getUniversalHeaderWithGroupedAppSwitcher,
  getUniversalHeaderWithOriginSearch,
  universalHeaderWithHeaderLinks,
} from './configs/universalHeaders';

export const StandaloneConfigOptions: React.FC<MicroAppInitializationProps> = (props) => {
  const { radio } = props;
  useUniversalHeader({
    radio: radio as unknown as Radio,
    microAppName: 'amzn-studios-title-base-manager-app',
    universalHeaderConfig: {
      productIdentifier: 'Universal Header Demo',
    },
  });
  const eventBus = useEventBus();
  const stage = getStage();
  const universalHeaderLogo = getUniversalHeaderLogo(stage);
  const universalHeaderWithAppSwitcher = getUniversalHeaderWithAppSwitcher(stage);
  const universalHeaderWithGroupedAppSwitcher = getUniversalHeaderWithGroupedAppSwitcher(stage);
  const universalHeaderWithOriginSearch = getUniversalHeaderWithOriginSearch();

  return (
    <Grid container sx={{ paddingTop: '80px' }}>
      <Grid container sx={{ padding: '1rem 1rem', flexDirection: 'column' }}>
        <Grid item sx={{ padding: '1rem' }}>
          <Button
            onClick={() =>
              eventBus.publish<ConfigureEventPayload>(EventNames.Configure, {
                payload: {
                  appName: 'universal-header-demo',
                  config: universalHeaderLogo,
                },
              })
            }>
            Configure Logo Section
          </Button>
        </Grid>
        <Grid item sx={{ padding: '1rem' }}>
          <Button
            onClick={() =>
              eventBus.publish<ConfigureEventPayload>(EventNames.Configure, {
                payload: {
                  appName: 'universal-header-demo',
                  config: universalHeaderWithAppSwitcher,
                },
              })
            }>
            Configure App Switcher
          </Button>
        </Grid>
        <Grid item sx={{ padding: '1rem' }}>
          <Button
            onClick={() =>
              eventBus.publish<ConfigureEventPayload>(EventNames.Configure, {
                payload: {
                  appName: 'universal-header-demo',
                  config: universalHeaderWithGroupedAppSwitcher,
                },
              })
            }>
            Configure Grouped App Switcher
          </Button>
        </Grid>
        <Grid item sx={{ padding: '1rem' }}>
          <Button
            onClick={() =>
              eventBus.publish<ConfigureEventPayload>(EventNames.Configure, {
                payload: {
                  appName: 'universal-header-demo',
                  config: universalHeaderWithHeaderLinks,
                },
              })
            }>
            Configure Header Links
          </Button>
        </Grid>
        <Grid item sx={{ padding: '1rem' }}>
          <Button
            onClick={() =>
              eventBus.publish<ConfigureEventPayload>(EventNames.Configure, {
                payload: {
                  appName: 'universal-header-demo',
                  config: universalHeaderWithOriginSearch,
                },
              })
            }>
            Configure Origin Search
          </Button>
        </Grid>
        <Grid item sx={{ padding: '1rem' }}>
          <Button
            onClick={() =>
              eventBus.publish<ConfigureEventPayload>(EventNames.Configure, {
                payload: {
                  appName: 'universal-header-demo',
                  config: {},
                },
              })
            }>
            Configure Fallback
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
