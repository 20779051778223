import { AppSwitcherConfigs, BacklotApps } from '@amzn/sitc-frontend/constants';
import { useFeature } from '@amzn/sitc-frontend/hooks';
import type { AppSwitcherContent } from '@amzn/sitc-frontend/types';

export const useDefaultAppSwitcherContent = (): AppSwitcherContent[] => {
  const sourceShareAppEnabled = useFeature('enableSourceShareApp');

  return [
    {
      subheader: 'universal_header.appswitcher.backlot_apps.subheader',
      navItems: [
        AppSwitcherConfigs[BacklotApps.Almanac],
        AppSwitcherConfigs[BacklotApps.HorizonAnalyze],
        AppSwitcherConfigs[BacklotApps.OriginAuthor],
        AppSwitcherConfigs[BacklotApps.OriginView],
        AppSwitcherConfigs[BacklotApps.Release],
        AppSwitcherConfigs[BacklotApps.SourceLibrary],
        AppSwitcherConfigs[BacklotApps.SourcePlan],
        ...(sourceShareAppEnabled ? [AppSwitcherConfigs[BacklotApps.SourceShare]] : []),
        AppSwitcherConfigs[BacklotApps.Mgm],
        AppSwitcherConfigs[BacklotApps.MgmLicensing],
        AppSwitcherConfigs[BacklotApps.Residuals],
        AppSwitcherConfigs[BacklotApps.DueDiligence],
        AppSwitcherConfigs[BacklotApps.LocalizationCentral],
        AppSwitcherConfigs[BacklotApps.StudiosApps],
      ],
    },
    {
      subheader: 'universal_header.appswitcher.third_party_apps.subheader',
      navItems: [
        AppSwitcherConfigs[BacklotApps.Airtable],
        AppSwitcherConfigs[BacklotApps.Rightsline],
        AppSwitcherConfigs[BacklotApps.Salesforce],
      ],
    },
  ];
};
