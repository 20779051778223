import type { MicroAppConfigContextValue } from '@amzn/sitc-frontend/dist/contexts';
import { useContext } from 'react';

import type { MicroAppConfigResultType } from '../../utils/app';
import { MicroAppConfigContext } from '../../utils/app';

export const useMicroAppConfig = (): MicroAppConfigContextValue<MicroAppConfigResultType> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const microAppConfigContext = useContext(MicroAppConfigContext);

  if (!microAppConfigContext) {
    throw new Error('Missing MicroAppConfigProvider');
  }

  return microAppConfigContext;
};
