import type { EntryRoot } from '@amzn/studios-domain-authority-types/isdg';
import { gql } from '@apollo/client';
import { BoolQuery, MatchQuery } from 'elastic-builder';

export type GetUserDataResponse = {
  directory: {
    traversalSearchFromDocumentRoots: {
      schemaedData: EntryRoot[];
    };
  };
};

export const buildUserQuery = (id = ''): string => {
  const query = new BoolQuery();

  query.must([new MatchQuery('documentGraph.vertices.data.serializedData.loginId', id)]);
  return JSON.stringify(query.toJSON());
};

export const getUserData = gql`
  query GetUserData($client: Client!, $queryJson: String!, $pageSize: Int!) {
    directory {
      traversalSearchFromDocumentRoots(
        props: { caller: { client: $client, user: null }, query: { queryJson: $queryJson, pageSize: $pageSize } }
      ) {
        schemaedData {
          ... on EntryRoot {
            out_AmazonPerson {
              firstName
              lastName
              primaryEmail
              loginId
              jobProfileName
            }
          }
        }
      }
    }
  }
`;
